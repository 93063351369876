<template>
  <div id="exec-bios-view" v-if="bio">
    <div class="image">
      <HfaImage :name="bio.id" path="assets/exec-bios" />
    </div>
    <div class="content">
      <h1>{{ bio.name }}</h1>
      <h2>{{ bio.position }}</h2>
      <div class="text" v-html="bio.content"></div>

      <router-link :to="{ name: 'exec-bios-index' }" class="button back-btn">
        &lt; Back
      </router-link>
    </div>
  </div>
</template>

<script>
import HfaImage from "@/components/HfaImage.vue";

export default {
  name: "ExecBiosView",
  components: {
    HfaImage
  },
  metaInfo: function() {
    return {
      title: ` - ${this.bio.name}`,
      meta: [
        {
          name: "description",
          content: this.bio.position
        }
      ],
      link: [
        {
          vmid: "canonical",
          rel: "canonical",
          href: this.$store.getters["canonical"]()
        }
      ]
    };
  },
  computed: {
    bio: function() {
      return this.$store.getters["executives/bioById"](this.$route.params.id);
    }
  }
};
</script>

<style lang="scss">
#exec-bios-view {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  .back-btn {
    margin-top: 20px;
    display: inline-block;
    font-size: 1.15em;
  }

  .image {
    flex: 0 0 auto;
    overflow: hidden;
    width: 75%;
    border-radius: 10px;
    margin: 0px;
    margin-bottom: 20px;

    img {
      width: 100%;
      display: block;
      transform: scale(1.08);
    }
  }
  .content {
    h1,
    h2 {
      margin: 0;
      font-weight: 400;
      text-align: center;
    }
    h1 {
      margin-bottom: 10px;
      font-size: 1.5em;
    }
    h2 {
      font-family: "Fjalla One", sans-serif;
      font-size: 1.25em;
      margin-bottom: 20px;
    }
    p {
      font-size: 1.1em;
    }
  }

  @media (min-width: $desktop) {
    flex-direction: row;
    align-items: flex-start;

    .image {
      margin-right: 65px;
      min-height: auto;
      height: auto;
      width: auto;

      img {
        width: auto;
      }
    }
    .content {
      margin-right: 100px;

      h1,
      h2 {
        color: #fff;
        text-align: left;
      }
      h1 {
        font-size: 2em;
      }
      h2 {
        font-size: 1.375em;
        margin-bottom: 80px;
      }
      p {
        font-size: 1.1875em;
      }
    }
  }
}
</style>
